import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Block from "../components/block";

const PageTemplate = ({ data }) => {
  const { title, seo, blocks, noIndex } = data.page;
  const seoTitle = seo?.title || title;
  const seoDescription = seo?.description?.description;
  const seoImage = seo?.image;
  const canonicalURL = seo?.canonicalURL;

  return (
    <Layout>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        canonicalURL={canonicalURL}
        noIndex={noIndex}
      />
      <Hero title={title} />
      {blocks?.map((block) => (
        <Block block={block} key={block.contentful_id} />
      ))}
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    page: contentfulPage(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      title
      seo {
        title
        canonicalURL
        description {
          description
        }
        image {
          description
          title
          gatsbyImageData(
            width: 1200
            height: 1200
            quality: 80
            resizingBehavior: FILL
          )
        }
      }
      blocks {
        ... on ContentfulRichText {
          contentful_id
          __typename
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(layout: FULL_WIDTH)
              }
              ... on ContentfulLink {
                contentful_id
                __typename
                id
                button
                buttonBackgroundColour
                link
                node_locale
                textColour
                target
                title
              }
              ... on ContentfulAssetOriginalRatio {
                contentful_id
                __typename
                id
                title
                image {
                  description
                  title
                  gatsbyImageData(layout: FULL_WIDTH)
                  file {
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on ContentfulImageWithText {
          contentful_id
          __typename
          image {
            description
            title
            gatsbyImageData(quality: 80)
          }
          mobileImage {
            gatsbyImageData(quality: 80)
          }
          imageAlt
          text {
            raw
          }
        }
        ... on ContentfulSlideshow {
          contentful_id
          __typename
          slides {
            contentful_id
            image {
              description
              title
              gatsbyImageData(quality: 80)
            }
            mobileImage {
              title
              gatsbyImageData(quality: 80)
            }
            imageAlt
            text {
              raw
            }
          }
          layout
          backgroundColor
          padding
        }
        ... on ContentfulTypeform {
          contentful_id
          __typename
          form
        }
        ... on ContentfulEmbed {
          contentful_id
          __typename
          url
        }
      }
      noIndex
    }
    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
